import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/office/:office_name',
    name: 'mainTabletView',
    component: () => import(/* webpackChunkName: "about" */ '../views/mainTabletView.vue')
  },
  {
    path: '/office/mobile/:office_name',
    name: 'mainMobileView',
    component: () => import(/* webpackChunkName: "about" */ '../views/mainMobileView.vue'),
  },
  {
    path: '/office/:office_name/waiting',
    name: 'waitingTablet',
    component: () => import(/* webpackChunkName: "about" */ '../views/waitingView.vue')
  },
  {
    path: '/office/:office_name/card',
    name: 'managerCard',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerCardView.vue')
  },
  {
    path: '/office/:office_name/error',
    name: 'errorPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/errorView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
